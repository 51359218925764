<template>
  <div v-loading="loading">
    <div class="dis align_center">
      <div class="icon"></div>
      <div class="service">添加服务活动填报表</div>
    </div>
    <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="170px" style="padding-top: 20px"
      :label-position="labelPosition">

      <el-form-item label="活动名称 " prop="activity_name">
        <el-input v-model="ruleForm.activity_name" placeholder="请输入活动名称" class="width_500"></el-input>

      </el-form-item>
      <el-form-item label="活动类别 " prop="service_category_id">
        <el-select v-model="ruleForm.service_category_id" placeholder="请选择活动类别" class="width_500">
          <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>

      </el-form-item>
      <el-form-item label="范围 " prop="scope">
        <el-input v-model="ruleForm.scope" placeholder="请输入范围" class="width_500"></el-input>

      </el-form-item>
      <el-form-item label="活动时间" prop="activity_time">
        <el-date-picker class="width_500" v-model="time" type="datetimerange" start-placeholder="开始日期"
          value-format="yyyy-MM-dd HH:mm:ss" :onPick="datatime()" end-placeholder="结束日期">
        </el-date-picker>

      </el-form-item>
      <el-form-item label="类型 " prop="type">
        <el-select v-model="ruleForm.type" placeholder="请选择类型 " class="width_500">
          <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="方式 " prop="mode">
        <el-select v-model="ruleForm.mode" placeholder="请选择方式 " class="width_500">
          <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="规模（家） " prop="scale">
        <el-input v-model="ruleForm.scale" placeholder="请输入规模数量" class="width_500"></el-input>
      </el-form-item>
      <el-form-item label="人数（家） " prop="people_num">
        <el-input v-model="ruleForm.people_num" placeholder="请输入人数" class="width_500"></el-input>
      </el-form-item>
      <el-form-item label="是否收费（元） " prop="charge">
        <el-radio-group @change="changeradio(ruleForm.charge)" v-model="ruleForm.charge">
          <el-radio v-model="ruleForm.charge" label="1">是</el-radio>
          <el-input v-model="ruleForm.price" placeholder="请输入费用" style="width: 200px;margin-right: 30px;"
            :disabled="ruleForm.charge == '2'"></el-input>
          <el-radio v-model="ruleForm.charge" label="2">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="指导部门" prop="department">
        <el-input v-model="ruleForm.department" placeholder="请输入指导部门" class="width_500"></el-input>
      </el-form-item>
      <el-form-item label="预算" prop="budget">
        <el-input v-model="ruleForm.budget" placeholder="请输入预算" class="width_500"></el-input>
      </el-form-item>
      <el-form-item label="费用来源" prop="source">
        <el-input v-model="ruleForm.source" placeholder="请输入费用来源" class="width_500"></el-input>
      </el-form-item>

      <div class="cwidth_50">
        <div class="" style="width: 170px;text-align: left;padding: 0 12px 0 0; height: 50px; line-height: 50px;">
          <span class="f34">*</span>签到表
        </div>
        <div class=" left">
          <el-upload ref="uploadfiles2" list-type="picture-card" class="avatar-uploader" action="name1"
            accept=".jpg,.jpeg,.png" :multiple="true" :limit="5" name="file2" :auto-upload="true"
            :file-list="uploadList1" :http-request="httpreq1" :before-upload="changeUpload1" :on-exceed="handleExceed"
            :on-remove="handleRemove1">
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
      </div>

      <div class="cwidth_50">
        <div class="" style="width: 170px;text-align: left;padding: 0 12px 0 0;height: 50px; line-height: 50px;">
          <span class="f34">*</span>现场照片
        </div>
        <div class=" left">
          <el-upload ref="uploadfiles2" list-type="picture-card" class="avatar-uploader" action="name2"
            accept=".jpg,.jpeg,.png" :multiple="true" :limit="5" name="file2" :auto-upload="true"
            :file-list="uploadList2" :http-request="httpreq1" :before-upload="changeUpload1" :on-exceed="handleExceed"
            :on-remove="handleRemove2">
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
      </div>

      <div class="cwidth_50">
        <div class="" style="width: 170px;text-align: left;padding: 0 12px 0 0;height: 50px; line-height: 50px;">
          <span class="f34">*</span>活动通知
        </div>
        <div class=" left">
          <el-upload ref="uploadfiles2" list-type="picture-card" class="avatar-uploader" action="name3"
            accept=".jpg,.jpeg,.png" :multiple="true" :limit="5" name="file2" :auto-upload="true"
            :file-list="uploadList3" :http-request="httpreq1" :before-upload="changeUpload1" :on-exceed="handleExceed"
            :on-remove="handleRemove3">
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
      </div>

      <div class="cwidth_50">
        <div class="" style="width: 170px;text-align: left;padding: 0 12px 0 0;height: 50px; line-height: 50px;">
          总结
        </div>
        <div class=" left">
          <el-upload ref="uploadfiles2" list-type="picture-card" class="avatar-uploader" action="name4"
            accept=".jpg,.jpeg,.png" :multiple="true" :limit="5" name="file2" :auto-upload="true"
            :file-list="uploadList4" :http-request="httpreq1" :before-upload="changeUpload1" :on-exceed="handleExceed"
            :on-remove="handleRemove4">
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
      </div>

      <div class="cwidth_50">
        <div class="" style="width: 170px;text-align: left;padding: 0 12px 0 0;height: 50px; line-height: 50px;">
          亮点
        </div>
        <div class=" left">
          <el-upload ref="uploadfiles2" list-type="picture-card" class="avatar-uploader" action="name5"
            accept=".jpg,.jpeg,.png" :multiple="true" :limit="5" name="file2" :auto-upload="true"
            :file-list="uploadList5" :http-request="httpreq1" :before-upload="changeUpload1" :on-exceed="handleExceed"
            :on-remove="handleRemove5">
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
      </div>
      <el-button @click="addproduct()" 
        style="background-color: #ff7129; color: #fefefe; margin-top: 30px">确认添加</el-button>

      <el-button style="background-color: #cfcfcf; color: #fefefe; margin-top: 30px" @click="balck()">取消</el-button>
    </el-form>
  </div>
</template>
<script>
import { json } from 'body-parser';

// import {formatStamp} from '../../assets/js/data'
export default {
  data() {
    return {
      time: "",
      upload1: [],
      upload2: [],
      upload3: [],
      upload4: [],
      upload5: [],
      uploadList1: [], // 上传列表 图片回显数组
      uploadList2: [], // 上传列表 图片回显数组
      uploadList3: [], // 上传列表 图片回显数组
      uploadList4: [], // 上传列表 图片回显数组
      uploadList5: [], // 上传列表 图片回显数组
      upimg:[],//修改后的图片
      loads1: [],  //文件上传先保存的地址 
      loads2: [], //文件上传先保存的地址 
      loads3: [], //文件上传先保存的地址 
      loads4: [], //文件上传先保存的地址 
      loads5: [],//文件上传先保存的地址
      img1: [],
      img: [],
      img2: [],
      img3: [],
      img4: [],
      img5: [],
      img1login: false,
      img2login: false,
      img3login: false,
      loading: false,
      options1: [],
      options2: [
        { value: 1, label: "公益性" },
        { value: 2, label: "市场化" }
      ],
      options3: [
        { value: 1, label: "线上" },
        { value: 2, label: "线下" },
        { value: 3, label: "集中" },
        { value: 4, label: "入企" }
      ],
      labelPosition: 'left',
      ruleForm: {
        activity_name: "",
        service_category_id: "",
        scope: "",
        activity_time: "",
        type: "",
        mode: "",
        scale: "",
        activity_end_time: "",
        people_num: "",
        charge: "",
        department: "",
        budget: "",
        source: "",
        price: ""
      },
      rules: {
        activity_name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { required: true, message: "活动名称不能为空", trigger: "change" },
        ],
        service_category_id: [{ required: true, message: "请选择活动类别" }],
        scope: [
          { required: true, message: "请输入范围", trigger: "blur" },
          { required: true, message: "范围不能为空", trigger: "change" },
        ],
        type: [{ required: true, message: "请选择类型" }],
        mode: [{ required: true, message: "请选择方式" }],
        scale: [
          { required: true, message: "请输入规模数量", trigger: "blur" },
          { required: true, message: "规模数量不能为空", trigger: "change" },
        ],
        people_num: [
          { required: true, message: "请输入人数", trigger: "blur" },
          { required: true, message: "人数不能为空", trigger: "change" },
        ],
        activity_time: [{ required: true, message: "请选择活动时间", trigger: "change" }],

        charge: [{ required: true, message: "请选择是否收费", trigger: "change" }],
        department: [
          { required: true, message: "请输入指导部门", trigger: "blur" },
          { required: true, message: "指导部门不能为空", trigger: "change" },
        ],
        budget: [
          { required: true, message: "请输入预算", trigger: "blur" },
          { required: true, message: "预算不能为空", trigger: "change" },
        ],
        source: [
          { required: true, message: "请输入费用来源", trigger: "blur" },
          { required: true, message: "费用来源不能为空", trigger: "change" },
        ],
      }
    }
  },
  created() {
    this.getcategory()
  },
  methods: {

    //是否收费
    changeradio(item) {
      this.ruleForm.charge = item
      console.log(this.ruleForm.charge, "7892456");

    },
    //提交
    addproduct() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm.price,'this.ruleForm.price')
          if(this.ruleForm.charge == 1 && this.ruleForm.price == ''){
            this.$message({
              message: '请输入收费金额',
              type: "warning",
              duration: 1500,
            });
            return
          }
          console.log('444')
          if (this.img1login == false) {
            this.$message({
              message: '请先上传签到表',
              type: "warning",
              duration: 1500,
            });
            return
          } else if (this.img2login == false) {
            this.$message({
              message: '请先上传现场照片',
              type: "warning",
              duration: 1500,
            });
            return
          } else if (this.img3login == false) {
            this.$message({
              message: '请先上传活动通知',
              type: "warning",
              duration: 1500,
            });
          } else {
            // if (this.imglogin == false) {
            //   this.addActivity()
            // } else {
              console.log('45464987312')
            this.loading = true
            Promise.all([ this.upload_img1(), this.upload_img2(), this.upload_img3() || this.upload_img4() || this.upload_img5()]).then((res) => {


              this.addActivity()
              console.log("res==>", res);

            }).catch((error) => {

              console.log("error==>", error);

            })
            // }
          }
        }


      });
    },
    //分割时间
    datatime() {
      console.log(this.time, 'this.time')
      this.ruleForm.activity_time = this.time[0]
      
      this.ruleForm.activity_end_time = this.time[1]
    },
    addActivity() {

      let data = {
        token: localStorage.eleToken,
        activity_name: this.ruleForm.activity_name,
        service_category_id: this.ruleForm.service_category_id,
        scope: this.ruleForm.scope,
        activity_time: this.ruleForm.activity_time,
        activity_end_time: this.ruleForm.activity_end_time,
        type: this.ruleForm.type,
        mode: this.ruleForm.mode,
        scale: this.ruleForm.scale,
        price: this.ruleForm.price,
        people_num: this.ruleForm.people_num,
        charge: this.ruleForm.charge,
        department: this.ruleForm.department,
        budget: this.ruleForm.budget,
        source: this.ruleForm.source,
        img: this.img
      };
      console.log(data, "data");
      this.$post("service_activity_add", data).then((res) => {
        if (res.status == 1) {
          this.imglogin = true
          console.log(res, "res");
          this.$message({
            message: res.msg,
            type: "success",
            duration: 1500,
          });
          this.$router.push("/serveWriting");
        }

      });
    },

    upload_img1() {
      return new Promise((resolve, reject) => {
      
          this.loads1.map((item) => {

            let formdata = new FormData();
            formdata.append("file", item);
            this.$post("upload_img", formdata).then((res) => {

              this.img.push({ 'img': res.address, 'type': 1 });
              this.imglogin = false
              resolve();
            });
          })




      })
    },
    upload_img2() {

      return new Promise((resolve, reject) => {

          this.loads2.map((item) => {
          let formdata = new FormData();
          formdata.append("file", item);
          this.$post("upload_img", formdata).then((res) => {
            this.img.push({ 'img': res.address, 'type': 2 });
            this.imglogin = false
            resolve();
          });
        })
        


       
      })
    },
    upload_img3() {
      return new Promise((resolve, reject) => {
        this.loads3.map((item) => {
          let formdata = new FormData();
          formdata.append("file", item);
          this.$post("upload_img", formdata).then((res) => {
            this.img.push({ 'img': res.address, 'type': 3 });
            this.imglogin = false
            console.log(this.img3, ' this.img3')
            resolve();
          });
        })
      })
    },
    upload_img4() {
      return new Promise((resolve, reject) => {
        this.loads4.map((item) => {
          let formdata = new FormData();
          formdata.append("file", item);
          this.$post("upload_img", formdata).then((res) => {
            this.img.push({ 'img': res.address, 'type': 4 });
            this.imglogin = false
            console.log(this.img4, ' this.img4')
            resolve();
          });
        })
      })
    },
    upload_img5() {
      return new Promise((resolve, reject) => {
        this.loads5.map((item) => {
          let formdata = new FormData();
          formdata.append("file", item);
          this.$post("upload_img", formdata).then((res) => {
            this.img.push({ 'img': res.address, 'type': 5 });
            this.imglogin = false
            resolve();
          });
        })
      })
    },
    httpreq1(load) {
      console.log(load,'load')
      switch (load.action){
       
        case 'name1':
        console.log(this.uploadList1,'this.uploadList1')
        this.img1login = true
        this.loads1.push(load.file)
        console.log(this.loads1, 'loads1')
      
      break;
      case 'name2':
        this.loads2.push(load.file)
        this.img2login = true
        console.log(this.loads2, 'loads2')
      
      break;
      case 'name3':
        this.loads3.push(load.file)
        this.img3login = true
        console.log(this.loads3, 'loads3')
      
      break;
      case 'name4':
        this.loads4.push(load.file)
        console.log(this.loads4, 'loads4')
      
      break;
      case 'name5':
        
        this.loads5.push(load.file)
        console.log(this.loads5, 'loads5')
      
      break;


      }
    },
    
    getcategory() {
      let data = {
        token: localStorage.eleToken,

      };
      this.$get("/service_category_lst", data).then((res) => {
        console.log(res, 'res')
        this.options1 = res.result.map((item) => {
          return {
            value: item.id,
            label: item.name
          }
        })
      });
    },
    handleExceed() {
      this.$message({
        message: "最大上传数量为5张!",
        type: "warning",
        duration: 1500,
      });
    },
    balck() {
      this.$router.push("/serveWriting");
      console.log(this.base);
    },
    changeUpload1(file, fileList) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "jpg";
      const extension1 = testmsg === "jpeg";
      const extension2 = testmsg === "png";

      if (!extension && !extension1 && !extension2) {
        this.$message({
          message: "上传文件只能是.jpg、.jpeg、.png格式!",
          type: "warning",
          duration: 1500,
        });
        console.log(this.option, ' this.option')
        this.img = "";

        this.headimg = "";
        this.uploadList = [];
        return false;
      }
      let formData = new FormData();

      formData.append("file", file.raw);
    },
    handleRemove1(file, fileList) {
            if (fileList.length === 0) {
                this.img1login = false
            }
            if (file.url.indexOf('blob:') === -1) {

                let data = {
                    img: file.url,
                    token: localStorage.eleToken,
                };
                this.$get("/del_service_img", data).then((res) => {
                    console.log(res, 'res')
                    this.$message({
                        message: res.msg,
                        type: "success",
                        duration: 1500,
                    });

                });
                // this.uploadList1 = [];
            }
        },
        handleRemove2(file, fileList) {
            if (fileList.length === 0) {
                this.img2login = false
            }
            if (file.url.indexOf('blob:') === -1) {
                let data = {
                    img: file.url,
                    token: localStorage.eleToken,
                };
                this.$get("/del_service_img", data).then((res) => {
                    console.log(res, 'res')
                    this.$message({
                        message: res.msg,
                        type: "success",
                        duration: 1500,
                    });
                });
            }
        },
        handleRemove3(file, fileList) {
            if (fileList.length === 0) {
                this.img3login = false
            }
            if (file.url.indexOf('blob:') === -1) {
                let data = {
                    img: file.url,
                    token: localStorage.eleToken,
                };
                this.$get("/del_service_img", data).then((res) => {
                    console.log(res, 'res')
                    this.$message({
                        message: res.msg,
                        type: "success",
                        duration: 1500,
                    });
                });
            }
        },
        handleRemove4(file, fileList) {
            if (file.url.indexOf('blob:') === -1) {
                let data = {
                    img: file.url,
                    token: localStorage.eleToken,
                };
                this.$get("/del_service_img", data).then((res) => {
                    console.log(res, 'res')
                    this.$message({
                        message: res.msg,
                        type: "success",
                        duration: 1500,
                    });
                });
            }
        },
        handleRemove5(file, fileList) {
            if (file.url.indexOf('blob:') === -1) {
                let data = {
                    img: file.url,
                    token: localStorage.eleToken,
                };
                this.$get("/del_service_img", data).then((res) => {
                    console.log(res, 'res')
                    this.$message({
                        message: res.msg,
                        type: "success",
                        duration: 1500,
                    });
                });
            }
        },


  }
}

</script>
<style lang="less">
.width_500 {
  width: 500px !important;
}
</style>

